@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #142247;
  --dark-blue: #223fa7;
  --blue: #0866bd;
  --light-blue: #2ea5db;

  --black: #292929;

  --dark-gray: #4f5976;
  --gray: #667085;
  --light-gray: #d0d5dd;
  --lighter-gray: #f0f1f5;
  --opaque-gray: #dae2e7;
  --whisper-gray: #f7f8fa;

  --white: #ffffff;
}

/* width */
::-webkit-scrollbar {
  width: 14px;
  height: 10px;
  /* display: none; */
}

/* Track */
::-webkit-scrollbar-track {
  background: #e8e8e8;
  border-left: 5px #f9fafb solid;
  /* display: none; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a3a9b9;
  border-left: 5px #f9fafb solid;
  background-clip: padding-box;
  /* display: none; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #969baa;
  /* display: none; */
}

html {
  font-size: 12px;
}

@media screen and (min-width: 1024px) {
  html {
    font-size: 14px;
  }
}

@media screen and (min-width: 1280px) {
  html {
    font-size: 16px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7f8fa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* ToggleSwitch component */
.toggle-switch button {
  background-color: var(--lighter-gray);
}
.toggle-switch button[aria-checked='true'] {
  background-color: var(--light-blue);
}

/* RadioButtonGroup component */
.radio-button-group.inline .radio-button-group-option {
  margin-top: 0;
  margin-right: 0.75rem;
}
.radio-button-group.inline .radio-button-group-option:last-child {
  margin-right: 0;
}

/* DatePicker component */
.react-datepicker {
  @apply flex;
}

.react-datepicker__aria-live {
  @apply hidden;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month-container:last-child {
  @apply border-l border-grayOutlineColor;
}

.react-datepicker__month {
  @apply flex flex-col px-4 pb-2 pt-1;
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold;
}

.react-datepicker__week {
  @apply flex justify-around mb-1;
}

.react-datepicker__day-names {
  @apply flex justify-around font-normal text-sm text-center mb-3 text-gray px-4 pt-1;
}

.react-datepicker__day-name {
  @apply w-8 h-8 flex items-center justify-center py-1 rounded-full;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply right-12 w-8 h-8 rounded transition flex items-center justify-center;
}

.react-datepicker__navigation--next {
  @apply right-4 w-8 h-8 rounded transition flex items-center justify-center;
}

.react-datepicker__day {
  @apply relative mb-1 w-10 h-8 flex items-center justify-center py-1 text-sm leading-loose transition cursor-pointer;
}

.react-datepicker__day--today::after {
  /* @apply bg-lighterGray rounded-full; */
  content: '';
  @apply absolute top-0 left-1 bg-lighterGray w-8 h-8 leading-8 rounded-full text-center;
  display: block !important;
}

.react-datepicker__day:last-child {
  @apply rounded-e-full;
}

.react-datepicker__day:first-child {
  @apply rounded-s-full;
}

.react-datepicker__day::after {
  @apply hidden;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40;
}

.react-datepicker__day--in-range {
  @apply bg-lighterBlue;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-lighterBlue;
}

.react-datepicker__day--selecting-range-start,
.react-datepicker__day--range-start {
  @apply text-white rounded-s-full;
}

.react-datepicker__day--selecting-range-end,
.react-datepicker__day--range-end {
  @apply text-white rounded-e-full;
}

.react-datepicker__day--range-start::after,
.react-datepicker__day--range-end::after,
.react-datepicker__day--selecting-range-start::after,
.react-datepicker__day--selecting-range-start::after,
.react-datepicker__day--selecting-range-end::after,
.react-datepicker__day--selected::after {
  content: '';
  @apply absolute top-0 left-1 bg-new-blue w-8 h-8 leading-8 rounded-full text-center text-pure-white;
  display: block !important;
}

.react-datepicker__day--001::after {
  content: '1';
}
.react-datepicker__day--002::after {
  content: '2';
}
.react-datepicker__day--003::after {
  content: '3';
}
.react-datepicker__day--004::after {
  content: '4';
}
.react-datepicker__day--005::after {
  content: '5';
}
.react-datepicker__day--006::after {
  content: '6';
}
.react-datepicker__day--007::after {
  content: '7';
}
.react-datepicker__day--008::after {
  content: '8';
}
.react-datepicker__day--009::after {
  content: '9';
}
.react-datepicker__day--010::after {
  content: '10';
}
.react-datepicker__day--011::after {
  content: '11';
}
.react-datepicker__day--012::after {
  content: '12';
}
.react-datepicker__day--013::after {
  content: '13';
}
.react-datepicker__day--014::after {
  content: '14';
}
.react-datepicker__day--015::after {
  content: '15';
}
.react-datepicker__day--016::after {
  content: '16';
}
.react-datepicker__day--017::after {
  content: '17';
}
.react-datepicker__day--018::after {
  content: '18';
}
.react-datepicker__day--019::after {
  content: '19';
}
.react-datepicker__day--020::after {
  content: '20';
}
.react-datepicker__day--021::after {
  content: '21';
}
.react-datepicker__day--022::after {
  content: '22';
}
.react-datepicker__day--023::after {
  content: '23';
}
.react-datepicker__day--024::after {
  content: '24';
}
.react-datepicker__day--025::after {
  content: '25';
}
.react-datepicker__day--026::after {
  content: '26';
}
.react-datepicker__day--027::after {
  content: '27';
}
.react-datepicker__day--028::after {
  content: '28';
}
.react-datepicker__day--029::after {
  content: '29';
}
.react-datepicker__day--030::after {
  content: '30';
}
.react-datepicker__day--031::after {
  content: '31';
}

.select-input__value-container .selected-item {
  background-color: white;
  padding: 0;
  margin: 0;
}
.select-input__value-container .selected-item .check-icon-container {
  display: none;
}
.select-input__single-value--is-disabled .selected-item {
  background-color: var(--whisper-gray);
}

.select-input__menu-portal {
  z-index: 100 !important;
}

.select-input__control {
  min-height: 2.8rem !important;
}
